<template>
  <div style="height: 100vh" class="container-fluid pa-0 ma-0">
    <div class="row pa-0 ma-0">
      <div class="col-lg-12 pa-0 ma-0">
        <div class="mainCnt">
          <div class="headerCnt">
            <div class="btnH">
              <img src="../assets/mainLogo.svg" alt="" srcset="">
              <button>
                Contact
              </button>
            </div>
            <div style="margin-top: 5rem" class="row mx-0">
              <div class="col-lg-6">
                <div class="mtxt">
               <h3>{{ routeName}} <br>
                  Instant Tax <br> Clearance <span style="color:#F4CF58">Certificate</span></h3>
                  <p>A Fast, effective and convenient medium provided for Tax payers to get their Tax Clearance
                    Certificate in less than <span style="color:#F4CF58">5 minutes</span> from any part of the world.
                  </p>
                  <div class="btnG">
                    <!-- <button class="getStarted">Get Started</button> -->
                    <a class="getStarted" target="_blank" @click="gettypes()">Get Started</a>
                    <button class="manual">Download Manual</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="imgDiv">
                  <img src="../assets/Group 42.svg" alt="" srcset="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hworks">
          <div class="wtxtH">
            <h3>How It Works</h3>
            <p>Follow these easy steps to get your Tax Clearance Certificate</p>
          </div>
          <div class="cardsW">
            <div class="row">
              <div class="col-lg-6">
                <div class="firstCrdW">
                  <img class="imgW" src="../assets/1.svg" alt="" srcset="">
                  <div class="cardOne">
                    <img src="../assets/Browsing online-pana 1.svg" alt="" srcset="">
                    <div class="browsingTxt">
                      <h4>Visit the Website</h4>
                      <p>{{ stateWebsite }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="firstCrdW">
                  <img class="" src="../assets/2.svg" alt="" srcset="">
                  <div class="cardOne">
                    <img src="../assets/Enter OTP-pana 1.svg" alt="" srcset="">
                    <div class="browsingTxt2">
                      <h4>{{ Inputidd }}</h4>
                      <p>{{ instructionOne }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cardsW">
            <div class="row">
              <div class="col-lg-6">
                <div class="firstCrdW">
                  <img class="" src="../assets/3.svg" alt="" srcset="">
                  <div class="cardOne">
                    <img src="../assets/Click here-pana 1.svg" alt="" srcset="">
                    <div class="browsingTxt3">
                      <h4>Select Tax Year</h4>
                      <p>{{ instructionTwo }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="firstCrdW">
                  <img class="" src="../assets/4.svg" alt="" srcset="">
                  <div class="cardOne">
                    <img src="../assets/Credit card-cuate 1.svg" alt="" srcset="">
                    <div class="browsingTxt4">
                      <h4>Make Payment</h4>
                      <p>{{ instructionThree }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="btnW">
            <a class="getStarted" target="_blank" :href="tccLinkaddress">Get Started</a>
            <button @click="getStarted" class="getStarted">Get Started</button> 
          </div> -->
        </div>
        <div class="footerDiv">
          <img style="margin-bottom: 28px" src="../assets/mainLogo.svg" alt="" srcset="">
          <p>  Copyright ©2022 <div v-if="this.who == 'Interswitch Limited'"><v-img src="../assets/logoInt.png" width="150"></v-img></div> <div v-else><p>Revotax</p></div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogRegsetbv" width="600">
    <v-card>
      <div style="width:20%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        Select Category
        <!-- <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon> -->
      </v-card-title>
      
      <div style="text-align: center; color: #8D8D8D; font-size: 16px;" class="mb-4">Select your preferred category</div>
      <!-- <v-divider style="margin-top:0px"></v-divider> -->

      <v-card-text>
        <div style="padding: 10px 30px 10px 30px; margin:10px 30px 10px 30px ;cursor: pointer;" @click="cardavail=true,cardavail1=false" v-if="!cardavail"><div class="row"><div class="col-md-2"> <img src="../assets/civils.svg"></div> <v-spacer></v-spacer><div style="color:#515151; font-size: 18px;padding-top: 25px;" class="col-md-8">Civil Servant/ Public Servant </div><div class="col-md-2"><v-icon @click="dialogRegset=false">mdi-tick-circle</v-icon></div></div></div>

<v-card style="padding: 10px 30px 10px 30px; margin:10px 30px 10px 30px ;cursor: pointer;" v-if="cardavail"><div class="row"><div class="col-md-2"> <img src="../assets/civils.svg"></div> <v-spacer></v-spacer><div style="color:#515151; font-size: 18px;padding-top: 25px;" class="col-md-8">Civil Servant / Public Servant</div><div class="col-md-2" style="padding-top:20px"><img src="../assets/tick.png" width="25"></div></div></v-card>


<div style="padding: 10px 30px 10px 30px; margin:10px 30px 10px 30px ;cursor: pointer;"  @click="cardavail1=true,cardavail=false" v-if="!cardavail1"><div class="row"><div class="col-md-2"> <img src="../assets/selfemp.svg"></div> <v-spacer></v-spacer><div style="color:#515151; font-size: 18px;padding-top: 25px;" class="col-md-8">Self Employed </div><div class="col-md-2"><v-icon @click="dialogRegset=false">mdi-tick-circle</v-icon></div></div></div>

<v-card style="padding: 10px 30px 10px 30px; margin:10px 30px 10px 30px ;cursor: pointer;" v-if="cardavail1"><div class="row"><div class="col-md-2"> <img src="../assets/selfemp.svg"></div> <v-spacer></v-spacer><div style="color:#515151; font-size: 18px;padding-top: 25px;" class="col-md-8">Self Employed </div><div class="col-md-2" style="padding-top:20px"><img src="../assets/tick.png" width="25"></div></div></v-card>
        <v-card-actions>

          <v-btn color="#096A65" class="col-md-5 m-auto mt-4" style="color:#fff" @click="movetoNext()" >Get Started</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>


  <v-dialog v-model="stateTin" width="600">
    <v-card>
      <div style="width:40%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        Provide your State TIN
        <!-- <v-icon @click="stateTin=false">mdi-close-circle</v-icon> -->
      </v-card-title>
      <!-- <div style="text-align: center; color: #8D8D8D; font-size: 16px;" class="mb-4">Select your preferred category</div> -->

      <v-card-text >
        <label style="margin-left:30px;">State Tax Identification Number</label>
        <v-text-field :label="Inputidd" outlined dense style="margin-left:30px; margin-right:30px" v-model="tin"></v-text-field>
        <label style="margin-left:30px;">Dont have your state {{ Inputidd }} / Cannot remember your {{Inputidd}}? <a :href="item==3?'https://selfportal.crossriver.tax':'https://selfportal.abia.tax'" target="_">Click here</a></label>

      </v-card-text>

      <v-card-actions>

<v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff" @click="gettaxInfo()" :disabled="!tin" v-if="!action">Proceed</v-btn>
<v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff" v-if="action"><v-progress-circular
      indeterminate
      color="white"
    ></v-progress-circular>
</v-btn>
</v-card-actions>

      </v-card>
      </v-dialog>
     



      <v-dialog v-model="tinInfo" width="600">
    <v-card>
      <div style="width:60%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        <v-spacer></v-spacer>
        <v-icon @click="tinInfo=false">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-text >
        <div class="row">
<div class="col-md-4">
<img :src="pimage" width="150" class="m-auto">
</div>
<div class="col-md-8">
        <v-text-field label="Fullnames" outlined dense style="margin-left:30px; margin-right:30px" v-model="fullname" readonly></v-text-field>

        <v-text-field label="Home Address" outlined dense style="margin-left:30px; margin-right:30px" v-model="address" readonly></v-text-field>

        <v-text-field label="Email" outlined dense style="margin-left:30px; margin-right:30px" v-model="email" readonly></v-text-field>

        <v-text-field label="Phone number" outlined dense style="margin-left:30px; margin-right:30px" v-model="phone" readonly></v-text-field>

        <v-text-field label="Institution" outlined dense style="margin-left:30px; margin-right:30px" v-model="inst" readonly></v-text-field>
</div>
        </div>
        
      </v-card-text>

      <v-card-actions>

<v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff" @click="getverifypay()" :disabled="!tin">Proceed</v-btn><br>
<!-- <v-btn color="#096A65" class="col-md-5" style="color:#fff" @click="cancelback()">Cancel</v-btn> -->

</v-card-actions>

      </v-card>
      </v-dialog>





      <v-dialog v-model="taxverify" width="600">
    <v-card>
      <div style="width:70%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        Retriving of staff data
        <!-- <v-icon @click="taxverify=false">mdi-close-circle</v-icon> -->
      </v-card-title>

      

      <div style="text-align: center; color: #8D8D8D; font-size: 16px;" class="mb-4">Please provide your employee staffID/Verification Number</div>

      <v-card-text >
        <div class="row">
<div class="col-md-2">
</div>
<div class="col-md-8">

        <!-- <v-autocomplete label="Select category" outlined dense style="margin-left:30px; margin-right:30px" v-model="cat" :items="['Federal', 'State']"></v-autocomplete> -->

        <!-- <v-autocomplete :items=allinst label="Institution" outlined dense style="margin-left:30px; margin-right:30px" v-model="insta"  @change="getgrade()"></v-autocomplete> -->

        <!-- <v-autocomplete :items=allgrades label="Grade Level" outlined dense style="margin-left:30px; margin-right:30px" v-model="grade" ></v-autocomplete> -->

        <!-- <v-autocomplete :items=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15] label="Step" outlined dense style="margin-left:30px; margin-right:30px" v-model="step" ></v-autocomplete> -->

        <v-autocomplete label="Select category" outlined dense style="margin-left:30px; margin-right:30px" v-model="cat" :items="['Federal', 'State']"></v-autocomplete>

        <v-text-field label="Employee staff number / Verification number" outlined dense style="margin-left:30px; margin-right:30px" v-model="staffId"></v-text-field>

<!-- 
        <v-autocomplete :items=years label="Certificate Year" outlined dense style="margin-left:30px; margin-right:30px" v-model="certyear"></v-autocomplete> -->
</div>
        </div>
        
      </v-card-text>

      <v-card-actions>

<v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff" @click="processCert()" :disabled="!tin" v-if="!action">Submit</v-btn><br>

<v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff" :disabled="!tin" v-if="action"><v-progress-circular
      indeterminate
      color="white"
    ></v-progress-circular></v-btn><br>
<!-- <v-btn color="#096A65" class="col-md-5" style="color:#fff" @click="cancelback()">Cancel</v-btn> -->

</v-card-actions>

      </v-card>
      </v-dialog>





      <v-dialog v-model="taxreprt" width="800" persistent>
    <v-card>
      <div style="width:90%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        <v-spacer></v-spacer>
        TAX SUMMARY
        <v-spacer></v-spacer>
        <v-icon @click="taxreprt=false">mdi-close-circle</v-icon>
      </v-card-title>

      <v-card-text v-if="taxreprt">
        <div class="row">
<div class="col-md-12">
  <div class="row" >
    <div class="col-md-1">
</div>
<div class="col-md-2" style="background-color: #FAFAFA; text-align: center; padding: 10px 0 10px 0">
<strong>Month</strong>
</div>
<div class="col-md-3" style="background-color: #ffff; text-align: center; center; padding: 10px 0 10px 0">
  <strong>{{datareport.yearnames.year3}}</strong>
</div>
<div class="col-md-3" style="background-color: #FAFAFA; text-align: center; center; padding: 10px 0 10px 0">
  <strong>{{datareport.yearnames.year2}}</strong>
</div>
<div class="col-md-3" style="background-color: #ffff; text-align: center; center; padding: 10px 0 10px 0">
  <strong>{{datareport.yearnames.year1}}</strong>

</div>
</div>
</div>

<div class="col-md-12" v-for="item in 12" :key="item">
  <div class="row">
    <div class="col-md-1">
</div>
<div class="col-md-2" style="background-color: #FAFAFA; text-align: center; center; padding: 10px 0 10px 0">
{{ convertMonth(item) }}
</div>
<div class="col-md-3" v-if="datareport.year3[0]" style="background-color: #ffff; text-align: center; center; padding: 10px 0 10px 0">
  <span v-if="datareport.year3[item-1] != undefined">&#8358;{{formatPrice(datareport.year3[item-1].tax)}}</span>
</div>
<div class="col-md-3" v-if="datareport.year2[0]" style="background-color: #FAFAFA; text-align: center; center; padding: 10px 0 10px 0">
  <span v-if="datareport.year2[item-1] != undefined">&#8358;{{formatPrice(datareport.year2[item-1].tax)}}</span>
</div>
<div class="col-md-3" v-if="datareport.year1[0]" style="background-color: #ffff; text-align: center; center; padding: 10px 0 10px 0">
  <span v-if="datareport.year1[item-1] != undefined">&#8358;{{formatPrice(datareport.year1[item-1].tax)}}</span>
  <span v-else>No record</span>
</div>
</div>
</div>
</div>
        
      </v-card-text>
      <v-card-actions>
        <v-alert text="..." type="info" style="width:100%" v-if="allGood">
         <strong>NOTE: </strong> <br>
          You are eigible  for the Tax Clearance Certificate (TCC) for {{datareport.yearnames.year3}}
          <v-spacer></v-spacer>

          <v-btn color="#096A65" class="col-md-3 m-auto mt-2" style="color:#fff" @click="processCertFinal()" v-if="!action">get Tcc</v-btn>
          <v-btn color="#096A65" class="col-md-3 m-auto mt-2" style="color:#fff" v-else>
            <v-progress-circular
      indeterminate
      color="white"
    ></v-progress-circular>
          </v-btn>
          <br>

        </v-alert>

        <v-alert text="..." type="error" style="width:100%" v-if="allBad">
         <strong>NOTE: </strong> <br>
The record(s) with ₦0 amount are missing in your profile. Thus you are not eligible for TCC. Visit the Board of Internal Revenue to update the records or seek for waiver to continue.
          <v-spacer></v-spacer>

          <v-btn color="#DD2025" class="col-md-3 m-auto" style="color:#fff" @click="taxreprt=false">Close</v-btn><br>

        </v-alert>
<!-- <v-btn color="#096A65" class="col-md-5" style="color:#fff" @click="cancelback()">Cancel</v-btn> -->

</v-card-actions>

      </v-card>
      </v-dialog>




      <v-dialog v-model="finalStep" width="600" persistent>
    <v-card>
      <div style="width:100%; height: 5px; background-color: #F4CF58;"></div>

      <v-card-title style="color:#666; font-size:18px;" primary-title class="justify-center mb-4">
        <v-spacer></v-spacer>
        CERTIFICATE
        <v-spacer></v-spacer>
        <v-icon @click="finalStep=false">mdi-close-circle</v-icon>

                <!-- <v-icon @click="stateTin=false">mdi-close-circle</v-icon> -->
      </v-card-title>

      <div style="text-align: center; color: #8D8D8D; font-size: 16px;" class="mb-4">Download or send certificate</div>

      <v-card-text >

<div class="col-md-12 ma-auto" style="margin-left:30px; margin-right:30px">
  <a class="ma-auto" style="display:flex; margin-left:30px; margin-right:30px">
        <img src="../assets/downloadCert.png" class="m-auto" @click="awsDownload(fileBa)">
        </a>
</div>
<v-divider style="margin-top: 20px;margin-bottom: 20px;"></v-divider>

<!-- <v-text-field label="Send Tcc to Email" outlined dense style="margin-left:30px; margin-right:30px" v-model="email"></v-text-field> -->


        <!-- <label style="margin-left:30px;">State Tax Identification Number</label>
        <v-text-field label="Enter State TIN" outlined dense style="margin-left:30px; margin-right:30px" v-model="tin"></v-text-field>
        <label style="margin-left:30px;">Dont have your state TIN / Cannot remember your TIN? <a href="#" target="_">Click here</a></label> -->

      </v-card-text>
<!-- 
      <v-card-actions>

        <v-btn color="#096A65" class="col-md-5 m-auto" style="color:#fff;" @click="sendTccemail()">Send to email</v-btn>
      </v-card-actions> -->

      </v-card>
      </v-dialog>






  </div>
</template>

<script>
import httpRequest from '../../services/httpRequest';
import Swal from 'sweetalert2';
const AWS    = require('aws-sdk');

// import axios from 'axios';
export default {
  data() {
    return {
      fileBa:'',
      dialogRegsetbv:false,
      action:false,
      datareport:'',
      allGood:false,
      allBad:false,
      emailFinal:this.email,
      taxreprt:false,
      tccdownloadPath:'',
      finalinfo:'',
      finalStep:false,
      certyear:'',
      allgrades:[],
      allinst:[],
      grade:'',
      level:'',
      cert:'',
      cat:'',
      insta:'',
      staffId:'',
      fullname:'',
      address:'',
      email:'',
      phone:'',
      inst:'',
      pimage:'',
      tinInfo:false,
      taxverify:false,
      tin:'',
      stateTin:false,
      cardavail1:false,
      cardavail:false,
      dialogRegset:false,
      tccLinkaddress: '',
      stateWebsite: '',
      instructionOne: '',
      instructionTwo: '',
      instructionThree: '',
      Inputidd: '',
      state:'',
      step:'',
      routeName:'',
      who:'Revotax Limited',
    }
  },
  mounted() {
    var retrievedObject = localStorage.getItem('stateObject');

      if(this.$route.params.id == 'crossriver' ||  JSON.parse(retrievedObject) == 3){
        this.routeName = 'Cross River'
        this.state = 3
        this.who = "Interswitch Limited"
        this.itemId = 3
       localStorage.removeItem('stateObject');
    }else{
  this.itemId = JSON.parse(retrievedObject);
    this.state = this.itemId;
    }
    console.log(this.itemId);
    // alert(this.itemId)
    // if(this.itemId == null){
    //   this.$router.push({name: 'selectState'})
    // }
    // const itemId = retrievedObject;
    httpRequest.get(`/state/${this.itemId}`).then(res => {
      // console.log('RESPONSE', res)
      const item = res.data.state
      console.log('this is the item', item.tccLink);
      this.tccLinkaddress = item.tccLink
      this.stateWebsite = item.website
      this.instructionOne = item.instOne
      this.instructionTwo = item.instTwo
      this.instructionThree = item.instThree
      this.Inputidd = item.idd
    })
const data = {stateId:this.itemId}
   httpRequest.post('/getinst',data).then(res => {
      console.log('RESPONSE', res)
      res.data.instValue.forEach(item => {

      this.allinst.push({
              text: item.name,
              value:item.id,
            })
          })
     
    })
    
    // console.log('Item',Item);
    // var currentURL = window.location.href;
    // if (currentURL === 'http://localhost:8084/cert') {
    //   console.log('url', currentURL)
    //   this.stateWebsite = Item.website
    //   this.instructionOne = Item.instOne
    //   this.instructionTwo = Item.instTwo
    //   this.instructionThree = Item.instThree
    // } else {
    //   window.localStorage.removeItem('stateObject');
    //   console.log('url', currentURL)
    // }
  },
  computed: {

    years() {
      const arr = [];
      for (let i = new Date().getFullYear()-1; i >= 2000; i--) {
       
          arr.push(i);
        
      }
      return arr;
    },
  },
  methods:{


awsDownload(key){
  var AWS_S3_BUCKETNAME = 'abiarevotax';
   var AWS_S3_SECRET     = process.env.VUE_APP_AWS_S3_SECRET;
   var AWS_S3_KEY        = process.env.VUE_APP_AWS_S3_KEY;


AWS.config.update({accessKeyId     : AWS_S3_KEY, 
                   secretAccessKey : AWS_S3_SECRET, 
                   signatureVersion: 'v4',
                   region:'eu-west-2'
                  });
const s3 = new AWS.S3();


var params = {
                Bucket : AWS_S3_BUCKETNAME,
                Key    : 'tcc/'+key,
                Expires: 60*5   //5 minutes
              };

 s3.getSignedUrl('getObject', params, function(err, url)
                 {
                    if (err) { throw {msg:err, code:"AWS_ERROR"}; }
                    else { 
                      console.log(url)
                      window.open(url, '_blank');
                    }
                 });  

},



    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
     convertMonth(x){
if(x == 1){
  return 'Jan'
}
if(x == 2){
  return 'Feb'
}if(x == 3){
  return 'Mar'
}if(x == 4){
  return 'Apr'
}if(x == 5){
  return 'May'
}if(x == 6){
  return 'Jun'
}if(x == 7){
  return 'Jul'
}if(x == 8){
  return 'Aug'
}if(x == 9){
  return 'Sep'
}if(x == 10){
  return 'Oct'
}if(x == 11){
  return 'Nov'
}if(x == 12){
  return 'Dec'
}
    },
    async getgrade(){
      const data={ministryId:this.insta}
      httpRequest.post('/getgrades',data).then(res => {
      console.log('RESPONSE', res)
      res.data.gradeValue.forEach(item => {
      this.allgrades.push({
              text: item.gradeName,
              value:{id:item.id, name:item.gradeName}
            })
          })
     
    })

    },

    //// process the certificate
    processCert(){
      this.action=true
      this.allBad = false; this.allGood=false
      if(this.staffId==''){
        this.action=false
        return Swal.fire({
                    icon: 'error',
                    title: 'Process tax certificate',
                    text: 'Please provide level as at year '+this.certyear,
                    showCloseButton: true,
                })
      }
      const data={taxId:this.tin,employeeNo:this.staffId,state:this.state}
      httpRequest.post('/process',data).then(res => {
        if(res.data.code == '10'){
          this.finalinfo = res.data.message
          this.tccdownloadPath = this.finalinfo.tcc_path
         this.taxverify = false
         this.finalStep = true
        }
        if(res.data.code == '00'){
          
          this.taxverify = false
        this.taxreprt = true
        this.datareport = res.data.message

        this.foundo = false
/// filter all years to find 0 instValue  or mergrge all years and look for 0
this.datareport.year1.forEach(element => {
  if(element.tax == '0'){
    this.foundo = true
return 
  }
  
});


this.datareport.year2.forEach(element => {
  if(element.tax == '0'){
    this.foundo = true
return 
  }
  
});

this.datareport.year3.forEach(element => {
  if(element.tax == '0'){
    this.foundo = true
return 
  }
  
});
if(this.foundo == true){
this.allBad = true
        }else{
this.allGood = true
        }
        this.action=false
        }
        if(res.data.code == '01'){
          this.action=false
          return Swal.fire({
                    icon: 'error',
                    title: 'Retriving of staff data',
                    text: res.data.message,
                    showCloseButton: true,
                })
        }
        if(res.data.code == '02'){
          this.action=false
          return Swal.fire({
                    icon: 'error',
                    title: 'Retriving of staff data',
                    text: res.data.message,
                    showCloseButton: true,
                })
        }
      // res.data.gradeValue.forEach(item => {
      // this.allgrades.push({
      //         text: item.gradeName,
      //         value:item.id,
      //       })
      //     })
     
    })
    },
    processCertFinal(){
      this.action=true
      this.finalStep = false
      console.log(this.datareport.year1);
      var data = {taxId:this.tin,employeeNo:this.staffId,state:this.state,tccYear:this.datareport.yearnames.year3,year2:this.datareport.yearnames.year2,year1:this.datareport.yearnames.year1,tccData3:this.datareport.year3,tccData2:this.datareport.year2,tccData1:this.datareport.year1}
       httpRequest.post('/finalProcess',{data}).then(response => {
        const res = response.data
        //1016558411
        if(res.code != '00') {

          this.action=false
          return Swal.fire({
            icon: 'error',
                    title: 'Find tax payer',
                    text: 'Tax payer with this ID '+ this.tin + ' not found',
                    showCloseButton: true,
                })
        }

        console.log(res);
        
        if(res.code == '00') {
          this.action=false
          this.taxreprt = false
this.finalStep = true
this.fileBa = res.message;
console.log(this.fileBa);
        }
        })

    },

     gettypes(){
      this.dialogRegsetbv=true
    },
     movetoNext(){
      this.dialogRegsetbv=false
      this.tin='';
if(this.cardavail != true && this.cardavail1 != true){
  return Swal.fire({
                    icon: 'error',
                    title: 'Category',
                    text: 'Please select a category',
                    showCloseButton: true,
                })
}
      if(this.cardavail1 == true){
        window.location.href =this.tccLinkaddress;
        this.dialogRegset=false
      }else{
        this.dialogRegset=false
        this.stateTin=true;
      }
    },

    async gettaxInfo(){
      this.action = true
      // if(this.tin !='12345678'){
      //     return Swal.fire({
      //               icon: 'error',
      //               title: 'Find tax payer',
      //               text: 'Tax payer with this ID '+ this.tin + ' not found',
      //               showCloseButton: true,
      //           })
      //   }else{
      //     this.tinInfo = true;
      //     this.fullname='NZEH PASCHAL UGWUANYA'
      //     this.address='No 35 odueze street'
      //     this.email='paschalugwuanya@yahoo.com'
      //     this.phone='08033180773'
      //     this.inst=''
      //     this.pimage='https://aws.appmartgroup.com/upload/199611108326670623291.png'
      //   }
      this.staffId = ''
      await httpRequest.post('/gettin',{tin:this.tin,state:this.state}).then(response => {
        const res = response.data
        if(res.code != '00') {
          this.action = false
          return Swal.fire({
            icon: 'error',
                    title: 'Find tax payer',
                    text: 'Tax payer with this ID '+ this.tin + ' not found',
                    showCloseButton: true,
                })
        }
        else{
          this.tinInfo = true;
          this.fullname= res.message.surname+' '+ res.message.first_name+' '+ res.message.middle_name
          this.address=res.message.home_address
          this.email=res.message.email_address
          this.phone=res.message.phone_number
          this.inst=res.message.ministry
          this.pimage=res.message.photo_url.includes("https")?res.message.photo_url:'https://tms.tax/photo/'+res.message.photo_url
        }
        this.action=false
        this.stateTin = false
        })
     
    },


//////////verify the tax imported payment
async getverifypay(){
  this.stateTin=false
this.tinInfo = false;
this.taxverify = true

}

    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.mainCnt {
  width: 100%;
  // height: auto;
  background: #096a65;
  padding: 32px 60px;

  .headerCnt {
    width: 100%;
    height: auto;

    .btnH {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */
        color: #FFFFFF;
      }
    }

    .mtxt {
      width: 100%;

      h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 750;
        font-size: 74px;
        // line-height: 89px;
        color: #FFFFFF;
      }

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 209.52%;
        /* or 38px */
        color: #FFFFFF;

      }

      .btnG {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .getStarted {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          width: 100%;
          height: 67px;
          background: #F4CF58;
          border-radius: 8px;
          margin-right: 1rem;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          /* identical to box height */
          color: #096A65;

        }

        .manual {
          width: 100%;
          height: 67px;
          background: #096A65;
          border: 1px solid #F4CF58;
          border-radius: 8px;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          /* identical to box height */
          color: #F4CF58;
        }

      }
    }

    .imgDiv {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      border: none;
    }

  }
}

.hworks {
  width: 100%;
  height: auto;
  background: #fff;
  // border: 1px solid blue;


  .wtxtH {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      /* identical to box height */
      color: #515151;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      color: #535353;
    }
  }

  .cardsW {
    margin: 2rem;
    width: 100%;

    .firstCrdW {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .imgW {
        margin-right: 2.5rem;
      }

      .cardOne {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 2rem;
        width: 451px;
        height: 481px;
        background: #FFFFFF;
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
        border-radius: 23px;

        img {
          padding: 58px 106px;
        }

        .browsingTxt {
          width: 50%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt2 {
          width: 80%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt3 {
          width: 80%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt4 {
          width: 90%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            text-align: center;
            color: #393939;
          }
        }
      }
    }

    .firstCrdW2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .imgW {
        margin-right: 2.5rem;
      }

      .cardOne {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 2rem;
        width: 451px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
        border-radius: 23px;

        img {
          padding: 58px 106px;
        }

        .browsingTxt {
          width: 50%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt2 {
          width: 80%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt3 {
          width: 80%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #393939;
          }
        }

        .browsingTxt4 {
          width: 90%;

          h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* identical to box height */
            text-align: center;
            color: #393939;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            text-align: center;
            color: #393939;
          }
        }
      }
    }
  }

  .btnW {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem 0;

    .getStarted {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 20%;
      height: 67px;
      background: #F4CF58;
      border-radius: 8px;
      margin-right: 1rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      color: #096A65;

    }
  }
}

.footerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 2rem;
  background: #096a65;
  padding: 32px 60px;

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
  }
}

@media(min-width: 320px) and (max-width: 1200px) {
  .mainCnt {
    width: 100%;
    // height: auto;
    background: #096a65;
    padding: 32px 6px;

    .headerCnt {
      width: 100%;
      height: auto;

      .btnH {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */
          color: #FFFFFF;
        }
      }

      .mtxt {
        width: 100%;
        margin-bottom: 7rem;

        h3 {
          font-family: 'Avenir LT Std';
          font-style: normal;
          font-weight: 750;
          font-size: 74px;
          // line-height: 89px;
          color: #FFFFFF;
        }

        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 209.52%;
          /* or 38px */
          color: #FFFFFF;

        }

        .btnG {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          .getStarted {
            width: 100%;
            height: 67px;
            background: #F4CF58;
            border-radius: 8px;
            margin-right: 1rem;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            /* identical to box height */
            color: #096A65;

          }

          .manual {
            width: 100%;
            height: 67px;
            background: #096A65;
            border: 1px solid #F4CF58;
            border-radius: 8px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            /* identical to box height */
            color: #F4CF58;
          }

        }
      }

      .imgDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        img {
          width: 100%;
        }
      }

    }
  }

  .hworks {
    padding: 1rem;
    width: 100%;
    height: auto;
    background: #fff;

    .wtxtH {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 2rem;

      h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #515151;
      }

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #535353;
      }
    }

    .cardsW {
      margin: 0rem;
      width: 100%;
      // display: flex;
      // justify-content: space-around;
      // align-items: flex-start;
      // flex-direction: column;

      .firstCrdW {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .imgW {
          margin-right: 0;
        }

        .cardOne {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 2rem 0;
          width: 100%;
          height: auto;
          background: #FFFFFF;
          box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
          border-radius: 23px;

          img {
            padding: 10px;
            width: 100%;
          }

          .browsingTxt {
            width: 100%;

            h4 {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              /* identical to box height */
              text-align: center;
              color: #393939;
            }

            p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              text-align: center;
              color: #393939;
            }
          }

          .browsingTxt2 {
            width: 100%;
            padding: 10px;

            h4 {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              text-align: center;
              color: #393939;
            }

            p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              text-align: center;
              color: #393939;
            }
          }

          .browsingTxt3 {
            width: 80%;

            h4 {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              /* identical to box height */
              text-align: center;
              color: #393939;
            }

            p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              text-align: center;
              color: #393939;
            }
          }

          .browsingTxt4 {
            width: 90%;

            h4 {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              /* identical to box height */
              text-align: center;
              color: #393939;
            }

            p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              text-align: center;
              color: #393939;
            }
          }
        }
      }
    }

    .btnW {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6rem;

      .getStarted {
        width: 50%;
        height: 67px;
        background: #F4CF58;
        border-radius: 8px;
        margin-right: 1rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */
        color: #096A65;

      }
    }
  }

  .footerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    background: #096a65;
    padding: 32px 60px;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      color: #FFFFFF;
    }
  }
}
</style>