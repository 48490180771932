import Vue from 'vue'
import VueRouter from 'vue-router'
import clearanceCert from '../components/clearanceCert'
import selectState from '../components/selectState'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'selectState',
    component: selectState
  },
  {
    path: '/cert',
    name: 'clearanceCert',
    component: clearanceCert,
    props: true,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/cert/:id',
    name: 'clearanceCert',
    component: clearanceCert,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) { 
    if (localStorage.getItem('stateObject')) {
    next();
    } else {
     next('/')
    }
  } else {
    next()
  }
})
export default router
