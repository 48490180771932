<template>
    <div style="height: 100vh" class="container-fluid pa-0 ma-0">
        <div class="row no-gutters">
            <div class="col-lg-12 no-gutters">
                <div class="mainDiv">
                    <div class="logDiv">
                        <h4>Welcome</h4>
                        <p>Select the State you need the Tax Clearance Certificate</p>
                        <b-form-select style="font-size: 15px;" v-model="selected" class="selForm"
                            prepend-icon="mdi-map-marker" :options="indiState"></b-form-select>
                        <!-- <select v-model="allstates" name="cars" id="cars"  show-menu-arrow class="form-select form-select-lg selForm">
                            <option selected>{{ allstates}}</option>
                            <option v-for="(i, index) in indiState" :key="index" :value="i">{{  i.state  }}</option>
                        </select> -->
                        <!-- <select v-model="food" class="form-select form-select-lg mb-3 " aria-label=".form-select-lg example" :options="foods"></select> -->
                        <button @click="getStarted" class="getStarted">Get Started</button>
                    </div>
                    <div class="ftxt">
                        <p>Copyright ©2022 Revotax Limited</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
// require("dotenv")
// import httpRequest from '../../services/httpRequest';
import axios from 'axios'
export default {
    data() {
        return {
            url: '',
            states: [],
            selected: null,
            indiState: [{ value: null, text: 'Select the State' },],
            selectedState: [],
            //allstatesx: 'Select States',
            allstates: 'Select States',
        }
    },

    mounted() {
        localStorage.clear()
    },

    beforeMount() {
        axios.get(process.env.VUE_APP_BASEURL+'/state').then(response => {
            console.log(response);
            response.data.Allstate.forEach(el => {
                this.indiState.push({
                    value: el.id,
                    text: el.state,
                })
            })
            console.log(this.indiState);
        });
        // const config = {
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         api_key: '8ad0c6fa17f48072e019d70808f6d258c6127e7b37a20f93df2aabd5dbe2d950df8cdb4d7fdc4a53d08a29902ca4492afe6627c598d206fabe8b0352b6bb1def'
        //     },
        // };
        // if (!config.headers.api_key) {
        //     console.log('You do not have access');
        // } else {            
        //     axios.get('http://gettcc.online:3000/api/v1/state', config).then(response => {
        //         console.log(response);
        //         response.data.Allstate.forEach(el => {
        //             this.indiState.push({
        //                 value: el.id,
        //                 text: el.state,
        //             })
        //         })
        //         console.log(this.indiState);
        //     });
        // }
    },
    methods: {
        getStarted() {
            if (this.selected === null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please Select a State',
                    width: 500,
                })
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Proceed',
                    showConfirmButton: false,
                    width: 500,
                    timer: 1000
                })
                // Put the object into storage
                localStorage.setItem('stateObject', JSON.stringify(this.selected));
                //    localStorage.setItem("apiData", JSON.stringify(res.data));
                console.log(this.selected);
                setTimeout(() => {
                    this.$router.push({
                        path: '/cert',
                    });
                }, 1500);
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.mainDiv {
    width: 100%;
    height: 100vh;
    background: #096a65;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .logDiv {
        width: 736px;
        height: 463px;
        background: #E5F4F4;
        box-shadow: 0px 17px 42px #074643;
        border-radius: 29px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 4rem;

        h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 750;
            font-size: 28px;
            line-height: 34px;
            /* identical to box height */
            color: #074643;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 29px;
            /* identical to box height */
            color: #074643;
        }

        .selForm {
            background-color: #F1F1F1;
            border: 1px solid #C7C7C7;
            border-radius: 8px;
            width: 100%;
            height: 76px;
            padding: 0 2rem;
        }

        .getStarted {
            width: 100%;
            height: 67px;
            background: #F4CF58;
            border-radius: 8px;
            margin-top: 2rem;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height */
            color: #096A65;

        }
    }

    .ftxt {
        position: absolute;
        bottom: 0%;

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height */
            color: #FFFFFF;
        }
    }

}

@media(min-width: 320px) and (max-width: 1200px) {
    .mainDiv {
        width: 100%;
        height: 100vh;
        background: #096a65;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 0 1rem;

        .logDiv {
            width: 100%;
            height: auto;
            background: #E5F4F4;
            box-shadow: 0px 17px 42px #074643;
            border-radius: 29px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            padding: 3rem 1rem;

            h4 {
                font-family: 'Avenir LT Std';
                font-style: normal;
                font-weight: 750;
                font-size: 28px;
                line-height: 34px;
                /* identical to box height */
                color: #000000;
                text-align: center;
            }

            p {
                font-family: 'Avenir LT Std';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                /* identical to box height */
                color: #393939;
                text-align: center;
            }

            .selForm {
                // background: #F1F1F1;
                border: 1px solid #C7C7C7;
                border-radius: 8px;
                width: 100%;
                height: 76px;
                padding: 0 2rem;
            }

            .getStarted {
                width: 100%;
                height: 67px;
                background: #F4CF58;
                border-radius: 8px;
                margin-top: 2rem;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                /* identical to box height */
                color: #096A65;

            }
        }

        .ftxt {
            position: absolute;
            bottom: 0%;

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                /* identical to box height */
                color: #FFFFFF;
                text-align: center;
            }
        }

    }
}
</style>