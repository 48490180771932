import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    withCredentials: false,
    method: 'post',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'api_key': '8ad0c6fa17f48072e019d70808f6d258c6127e7b37a20f93df2aabd5dbe2d950df8cdb4d7fdc4a53d08a29902ca4492afe6627c598d206fabe8b0352b6bb1def'
        // 'Authorization': 'Bearer '+ 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiYWRtaW5UeXBlIjoxLCJmaXJzdE5hbWUiOiJPZ2EiLCJsYXN0TmFtZSI6Ik1ha28iLCJlbWFpbCI6Im9nYW1ha29AYXBwbWFydGdyb3VwLmNvbSIsInBob25lIjoiMDkwODM0NTQ5MzgzIiwicGFzc3dvcmRSZXNldCI6MSwiY3JlYXRlZEJ5IjpudWxsLCJpYXQiOjE2MzM5OTc3MTcsImV4cCI6MTYzNjU4OTcxN30.Lfy7tmLkxnONg2zShoNUGZy6NSs5dXQu6Xxvph2CGuo'      
    },
})

export default {
    get(path){
        return apiClient.get(path)
    },
    post(path, data) {
        console.log(data)
        return apiClient.post(path, data)
    }
}